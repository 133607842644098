var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-panel"},[_c('ul',{staticClass:"contents"},_vm._l((_vm.contents),function(group,grIndex){return _c('li',{key:grIndex,staticClass:"contents__group"},[(_vm.contents.length > 1)?_c('span',{staticClass:"contents__group__title"},[_vm._v(" "+_vm._s(_vm.$t(group.name))+" ")]):_vm._e(),_c('Container',{staticClass:"contents__group__items",attrs:{"behaviour":"copy","group-name":"content","non-drag-area-selector":".soon, .disabled","get-child-payload":function (idx) { return _vm.getChildPayload(idx, grIndex); }},on:{"drag-start":function (ref) {
	var isSource = ref.isSource;
	var payload = ref.payload;

	return _vm.updateDndState({ isSource: isSource, payload: payload, value: true });
},"drag-end":function (ref) {
	var isSource = ref.isSource;
	var payload = ref.payload;

	return _vm.updateDndState({ isSource: isSource, payload: payload, value: false });
}}},_vm._l((group.items),function(item){return (!item.checkActive || item.checkActive().get())?_c('Draggable',{key:item.id,class:_vm.getContentClass(item),attrs:{"data-tested-as":item.componentId}},[(item.consumable)?_c('el-badge',{attrs:{"value":item.consumable - (_vm.globalstates.componentsInventory[item.componentId] || 0)}}):_vm._e(),_c('el-tooltip',{attrs:{"placement":"right","disabled":!item.tooltip,"content":_vm.$t(item.tooltip)}},[_c('el-card',{attrs:{"shadow":"never"}},[(item.state)?_c('div',{staticClass:"ribbon ribbon-top-right"},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(item.state)+" ")])]):_vm._e(),_c(item.icon,{tag:"component"}),_c('div',{staticClass:"description"},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])],1)],1)],1):_vm._e()}),1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }