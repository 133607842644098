export default {
  dragState: {
    anticipate: false,
    onDrag: false
  },
  groupeNames: {
    1: 't_one_column_',
    2: 't_two_columns_',
    3: 't_three_columns_',
    4: 't_four_columns_',
    5: 't_five_columns_',
    6: 't_six_columns_'
  },
  searchInput: '',
  groupedItems: [],
  items: [
    {
      name: '100',
      componentId: 'mj-section',
      childrenNbr: 1
    },
    {
      name: '30 70',
      componentId: 'mj-section',
      childrenNbr: 2,
      columsAttributes: [
        { width: '30%' },
        { width: '70%' }
      ]
    },
    // Vuepress documentation exemple
    // Object@mjSection4060Structure
    {
      name: '40 60',
      componentId: 'mj-section',
      childrenNbr: 2,
      columsAttributes: [
        { width: '40%' },
        { width: '60%' }
      ]
    },
    // Object@mjSection4060Structure
    {
      name: '50 50',
      componentId: 'mj-section',
      childrenNbr: 2
    },
    {
      name: '60 40',
      componentId: 'mj-section',
      childrenNbr: 2,
      columsAttributes: [
        { width: '60%' },
        { width: '40%' }
      ]
    },
    {
      name: '70 30',
      componentId: 'mj-section',
      childrenNbr: 2,
      columsAttributes: [
        { width: '70%' },
        { width: '30%' }
      ]
    },
    {
      name: '33 33 33',
      componentId: 'mj-section',
      childrenNbr: 3
    },
    {
      name: '40 30 30',
      componentId: 'mj-section',
      childrenNbr: 3,
      columsAttributes: [
        { width: '40%' },
        { width: '30%' },
        { width: '30%' }
      ]
    },
    {
      name: '50 25 25',
      componentId: 'mj-section',
      childrenNbr: 3,
      columsAttributes: [
        { width: '50%' },
        { width: '25%' },
        { width: '25%' }
      ]
    },
    {
      name: '25 25 50',
      componentId: 'mj-section',
      childrenNbr: 3,
      columsAttributes: [
        { width: '25%' },
        { width: '25%' },
        { width: '50%' }
      ]
    },
    {
      name: '30 30 40',
      componentId: 'mj-section',
      childrenNbr: 3,
      columsAttributes: [
        { width: '30%' },
        { width: '30%' },
        { width: '40%' }
      ]
    },
    {
      name: '40 40 20',
      componentId: 'mj-section',
      childrenNbr: 3,
      columsAttributes: [
        { width: '40%' },
        { width: '40%' },
        { width: '20%' }
      ]
    },
    {
      name: '25 25 25 25',
      componentId: 'mj-section',
      childrenNbr: 4
    },
    {
      name: '20 20 20 40',
      componentId: 'mj-section',
      childrenNbr: 4,
      columsAttributes: [
        { width: '20%' },
        { width: '20%' },
        { width: '20%' },
        { width: '40%' }
      ]
    },
    {
      name: '40 20 20 20',
      componentId: 'mj-section',
      childrenNbr: 4,
      columsAttributes: [
        { width: '40%' },
        { width: '20%' },
        { width: '20%' },
        { width: '20%' }
      ]
    },
    {
      name: '20 20 20 20 20',
      componentId: 'mj-section',
      childrenNbr: 5
    },
    {
      name: '17 17 17 17 17 17',
      componentId: 'mj-section',
      childrenNbr: 6
    }
  ]
}
