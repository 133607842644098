// Component states
import states from './default-template-modifier.state.js'
import MdiViewQuilt from 'vue-material-design-icons/ViewQuilt.vue'
import MdiChevronDown from 'vue-material-design-icons/ChevronDown.vue'
import workspaceStates from '@/components/parts/workspace/workspace.state.js'
import globalstates from '@/services/states/states.config.json'

// Services
import { shiftShortcuts } from '@/services/states/states.service'

// Libraries
import {
  find as _find,
  cloneDeep as _cloneDeep,
  debounce as _debounce
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Default-template-modifier'

// Vue@data
const data = function () {
  return {
    states,
    workspaceStates,
    rules: {
      name: [
        {
          message: this.$t('t_error-template-name_'),
          required: true,
          trigger: 'blur'
        }
      ]
    },
    globalstates
  }
}
// Vue@data

// Vue@subComponents
const components = {
  MdiViewQuilt,
  MdiChevronDown
}
// Vue@subComponents

const methods = {

  shiftShortcuts: shiftShortcuts,

  /**
   * Query string filter
   * @param  {String}   queryString (String to search)
   * @param  {Function} cb          (callback)
   */
  querySearch (queryString, cb) {
    const templates = this.states.templates
    const results = queryString ? templates.filter(this.createFilter(queryString)) : templates

    cb(results)
  },

  /**
   * Filter for each
   * @param  {String}  queryString (query)
   * @return {Function}            (filter function)
   */
  createFilter (queryString) {
    return template => template.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0
  },

  /**
   * On template select
   * @param  {Object} templateToLoad (template to load)
   */
  onTemplateSelect (templateToLoad) {
    this.states.selected = _cloneDeep(templateToLoad)
  },

  /**
   * Clear template
   */
  onTemplateClear () {
    this.states.selected = { id: null, label: null, shared: false }
  },

  // Func@dropdownActions
  /**
   * Switch mode depending param or update dropdown selected template
   * @param  {String/Object} param ('save_as_new_template'/template)
   */
  dropdownActions (param) {
    this.$set(states, 'selected', param)
  },
  // Func@dropdownActions

  // Func@enableSaveMode
  /**
   * Enable save mode
   */
  enableSaveMode () {
    this.$set(states.save, 'active', true)
    this.$set(states.save, 'overwrite', Boolean(this.states.selected.id))
    this.$set(states.save, 'value', this.states.selected.label)
  },
  // Func@enableSaveMode

  // Func@applyTemplate
  /**
   * Load and apply selected template to workspace
   */
  async applyTemplate () {
    this.$set(this.states.save, 'confirm', false)
    if (!this.hasJwtToken) {
      console.warn('No jwt Token: applyTemplate request is disabled')
      return
    }

    try {
      const id = this.states.selected.id
      this.states.loading = true
      this.workspaceStates.template = await this.api.templates(id).load()
    } finally {
      this.states.loading = false
    }
  },
  // Func@applyTemplate

  // Func@updateTemplatesList
  /**
   * Update templates list
   * @param  {Object} data (id, template_name)
   */
  updateTemplatesList (template) {
    const { id, label } = template
    const templateInList = _find(this.states.templates, { id })
    if (templateInList) {
      template.label = label
      return
    }
    this.states.templates.push(template)
  },
  // Func@updateTemplatesList

  // Func@disableSaveMode
  /**
   * Return to initiale state with
   * template dropdown
   */
  disableSaveMode () {
    this.$set(states.save, 'active', false)
  },
  // Func@disableSaveMode

  // Func@submitForm
  /**
   * Submit form and check name
   * @param  {String} method (put or patch)
   */
  submitForm (method) {
    this
      .$refs.templateForm
      .validate(valid => {
        if (!valid) return
        this.save(method)
      })
  },
  // Func@submitForm

  // Func@save
  /**
   * Save new templates or overwrite an existing one
   * @param  {String} method (put or patch)
   */
  async save (method) {
    if (!this.hasJwtToken) {
      console.warn('No jwt Token: Save request is disabled')
      return
    }
    const id = this.states.selected.id || null
    const template = this.workspaceStates.template
    this.$set(states.save, 'value', this.states.templateForm.name)
    this.states.loading = true

    try {
      const templates = await this.api.templates(id)[method]({
        name: this.states.save.value,
        mjml: template
      })
      this.updateTemplatesList(templates)
      this.disableSaveMode()
    } finally {
      this.$set(states, 'selected', {})
      this.states.loading = false
      this.states.templateForm.name = ''
    }
  },
  // Func@save

  // Func@removeTemplate
  /**
   * Remove selected block from group
   */
  async removeTemplate () {
    if (!this.hasJwtToken) {
      console.warn('No jwt Token: Remove request is disabled')
      return
    }
    const id = this.states.selected.id || null
    this.states.loading = true
    try {
      await this.api.templates(id).remove()
    } finally {
      this.states.loading = false
    }
    this.$set(states, 'selected', {})
    this.states.visibleDeleteDialog = false
  },
  // Func@removeTemplate,

  // Func@handleMessages
  /**
   * Handle emit messages
   */
  handleMessages (event) {
    const call = event.data.call
    if (typeof event.data !== 'object') return
    switch (call) {
      case 'factorly:template-removed':
        this.loadTemplatesDebounced({ refresh: true })
        break
      case 'factorly:save-new-template':
        this.$set(states.templateForm, 'name', event.data.value)
        this.save('save')
        break
      case 'factorly:update-template':
        // eslint-disable-next-line no-case-declarations
        const { name, id } = event.data.value
        this.$set(states.templateForm, 'name', name)
        this.$set(states.selected, 'id', id)
        this.save('update')
        break
    }
  },
  // Func@handleMessages

  // Func@getTemplates
  /**
   * Load templates list for the group
   */
  async getTemplates () {
    if (!this.hasJwtToken) {
      console.warn('No jwt Token: getTemplates request is disabled')
      return
    }

    try {
      this.states.loading = true
      const templates = await this.api.templates().load()
      this.$set(states, 'templates', templates)
    } finally {
      this.states.loading = false
    }
  }
  // Func@getTemplates
}

const computed = {

  // Func@isEmptyList
  /**
   * Check if list is empty
   * @return {Boolean}
   */
  isEmptyList () {
    return this.states.templates.length === 0
  },
  // Func@isEmptyList

  // Func@loadingIcons
  /**
   * Depending loading state, display loading icon on
   * input field
   * @return {String} (Element ui Icon class)
   */
  loadingIcons () {
    return this.states.loading ? 'el-icon-loading' : null
  },
  // Func@loadingIcons

  // Func@hasJwtToken
  /**
   * Check if we are able to reach API
   * @return {Boolean}
   */
  hasJwtToken () {
    return Boolean(this.api.token().get())
  },
  // Func@hasJwtToken

  // Func@hasResourceWrite
  /**
   * Check if we are able to create/edit resources
   * @return {Boolean}
   */
  hasResourceWrite () {
    return globalstates.workspace.resourceWriteAuthorized
  }
  // Func@hasResourceWrite
}

// Func@addWindowListener on create
/**
 * Add window resize event listener and define save html
 * request to be able to flush it on destroy
 */
function created () {
  window.addEventListener('message', this.handleMessages)
  this.loadTemplatesDebounced = _debounce(this.getTemplates, 500)
}
// Func@addWindowListener

// Func@loadTemplates
/**
 * Load templates list for the group
 */
async function loadTemplates () {
  await this.getTemplates()
}
// Func@loadTemplates

// Vue component syntax
export default {
  name,
  data,
  mounted: loadTemplates,
  created: created,
  methods,
  computed,
  components
}
