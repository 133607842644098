import Vue from 'vue'
import App from './components/app/App.vue'
import router from './router'

// Plugins
import './plugins/axios'
import './plugins/element.js'
import './plugins/ckEditor.js'
import './plugins/shortkey.js'
import './plugins/clipboard.js'
import './plugins/jsonEditor.js'
import i18n from './plugins/i18n'
import resize from 'vue-element-resize-detector'
import './plugins/vue'

// Monitoring
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

// Css
import './main.css'
import './assets/styles/fonts.scss'
import './assets/styles/perso-el-dialog.scss'

// Cypress
import installCypressHooks from 'cypressautomocker/include-in-webapp'
installCypressHooks()

Vue.config.productionTip = false
Vue.use(resize)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

// Expose i18n locale property
Object.defineProperty(Vue.prototype, '$locale', {
  get: function () {
    return i18n.locale
  },
  set: function (locale) {
    i18n.locale = locale
  }
})

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://a2bca0c3c3b84b0d92220dc358d33b9c@sentry.numberly.net/47',
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true
      })
    ],
    beforeBreadcrumb (breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        const { target } = hint.event
        if (target.ariaLabel) breadcrumb.message = target.ariaLabel
      }

      return breadcrumb
    }
  })
}

console.info(`%c Environment: ${process.env.NODE_ENV}`, 'color: #E6A23C;')
console.info(`%c Version: ${process.env.VERSION}`, 'color: #E6A23C;')
console.info(`%c Devmode: ${process.env.DEV_MODE}`, `color: ${process.env.DEV_MODE ? '#67C23A' : '#F56C6C'};`)
