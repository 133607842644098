// Component states
import states from './structure-panel.state.js'

// Components

import StructureLibrary from '@/components/standalone/structure-library/Structure-library.vue'
import StructureBlocks from '@/components/standalone/structure-blocks/Structure-blocks.vue'
import SettingsGeneralModifier from '@/components/standalone/settings-general-modifier/Settings-general-modifier.vue'

// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'

// Library
import {
  get as _get,
  findIndex as _findIndex
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Structure-panel'

// Data
const data = function () {
  return {
    states,
    shortcuts
  }
}

// Vue@subComponents
const components = {
  StructureLibrary,
  StructureBlocks,
  SettingsGeneralModifier
}
// Vue@subComponents

// Methods
const methods = {

  // Func@switchPane
  /**
   * Switch pane mode (General/Structure/Contents)
   */
  switchModifier () {
    const modifiers = this.states.sections
    const index = _findIndex(modifiers, { id: this.states.active })
    const nextModifier = _get(modifiers, `${index + 1}.id`, modifiers[0].id)
    this.$set(states, 'active', nextModifier)
  }
  // Func@switchPane

}

// Func@mounted
function mounted () {
  // Add aria label for Sentry
  this.$nextTick(function () {
    for (const section of this.$refs.sections) {
      section.$el
        .querySelector('.el-collapse-item__header')
        .setAttribute('aria-label', section.$el.getAttribute('aria-label'))
    }
  })
}

// Vue component syntax
export default {
  name,
  data,
  methods,
  components,
  mounted
}
