// Services
import { globalstates } from '@/services/states/states.service'

// Library
import {
  get as _get
} from 'lodash-es'

export default {
  html: _get(globalstates, 'workspace', {})
}
