// Component states
import states from './structure-blocks.state.js'

// Services
import { globalstates, openBlockBuilderPanel, shiftShortcuts } from '@/services/states/states.service'

// Components
import { Container, Draggable } from 'vue-smooth-dnd'

// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'

// Libraries
import {
  get as _get,
  debounce as _debounce
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Structure-blocks'

// Vue@props
const props = {
  parentStates: Object,
  isActive: Boolean
}
// Vue@props

// Data
const data = function () {
  return {
    states,
    blockBuilderPanelState: _get(globalstates, 'leftPanel.blockBuilderPanel', {}),
    shortcuts,
    globalstates
  }
}

// Vue@subComponents
const components = {
  Container,
  Draggable
}
// Vue@subComponents

// Methods
const methods = {

  shiftShortcuts: shiftShortcuts,

  /**
   * Focus seach field (used by shortcut)
   */
  focusSearch () {
    this.$refs['search-structure-blocks']
      .$el
      .querySelector('.el-input__inner')
      .focus()
  },

  // Func@openBlockBuilder
  /**
   * Open block builder panel
   */
  openBlockBuilder () {
    openBlockBuilderPanel()
  },
  // Func@openBlockBuilderPanel

  // Func@loadMore
  /**
   * Get more blocks
   */
  loadMore () {
    if (this.states.loading || this.states.loadingScroll || this.parentStates.active !== 'saved-blocks') {
      return
    }
    if (this.states.blocks.length === this.states.total && this.states.blocks.length !== 0) {
      this.states.noMore = true
      this.states.loading = false
      this.states.loadingScroll = false
    } else if (this.states.blocks.length < this.states.total) {
      this.getBlocks()
    }
  },
  // Func@loadMore

  // Func@getBlocks
  /**
   * Blocks API call (get)
   * @param  {Boolean} options.refresh (refresh bloc list)
   *
   * @return {Promise}
   */
  async getBlocks ({ refresh } = {}) {
    if (!this.hasJwtToken) {
      console.warn('No jwt Token: loadBlocks request is disabled')
      return
    }

    // Init loader status
    this.states.loading = true
    this.states.loadingScroll = true

    try {
      const response = await this.api
        .blocks()
        .load({
          p: refresh ? 1 : this.states.currentPage,
          s: this.states.searchInput,
          l: this.states.limit
        })
      const blocks = await response.blocks
      // Reset block list if asked or empty results
      if (refresh || !blocks.length) this.resetBlocks()
      this.states.total = response.total
      this.states.blocks.push(...blocks)
      this.$set(states, 'init', false)
      this.states.currentPage++
    } finally {
      this.states.loading = false
      this.states.loadingScroll = false
    }
  },
  // Func@getBlocks

  // Func@resetBlocks
  /**
   * Remove selected block from group, emit event to factorly
   */
  resetBlocks () {
    this.states.total = 0
    this.states.currentPage = 1
    this.$set(states, 'blocks', [])
  },
  // Func@resetBlocks

  // Func@updateBlockName
  /**
   * Update block name
   * @param  {Object}    Block to change
   *
   * @return {Object}         (Block data)
   */
  async updateBlockName (block) {
    this.states.loading = true
    try {
      await this.api
        .blocks(block.id)
        .update({ name: block.name })
    } finally {
      this.states.loading = false
    }
  },

  // Func@removeBlock
  /**
   * Remove selected block from group, emit event to factorly
   */
  async removeBlock () {
    if (!this.hasJwtToken) {
      console.warn('No jwt Token: Remove request is disabled')
      return
    }
    const id = this.states.selectedBlock.id || null
    this.states.loading = true
    try {
      await this.api.blocks(id).remove()
    } finally {
      this.states.loading = false
    }
    this.states.selectedBlock = null
    this.states.visibleDeleteDialog = false
  },
  // Func@removeBlock

  // Func@getChildPayload
  /**
   * Get payload of the current item ready to drop
   * @param  {Int}    indexGroup (Index of the group)
   * @param  {Object} Goup       (Group of structure)
   *
   * @return {Object}         (Item data)
   */
  getChildPayload (index) {
    return this.states.blocks[index]
  },
  // Func@getChildPayload

  // Func@handleMessages
  /**
   * Handle emit messages
   */
  handleMessages (event) {
    if (typeof event.data !== 'object') return
    const call = event.data.call
    if (call === 'factorly:block-saved' || call === 'factorly:block-removed') {
      this.loadBlocksDebounced({ refresh: true })
    }
  }
  // Func@handleMessages
}

// Computed Methods
const computed = {

  // Func@hasJwtToken
  /**
   * Check if we are able to reach API
   * @return {Boolean}
   */
  hasJwtToken () {
    return Boolean(this.api.token().get())
  },
  // Func@hasJwtToken

  // Func@hasResourceWrite
  /**
   * Check if we are able to create/edit resources
   * @return {Boolean}
   */
  hasResourceWrite () {
    return globalstates.workspace.resourceWriteAuthorized
  }
  // Func@hasResourceWrite
}

// Func@addWindowListener on create
/**
 * Add window resize event listener and define save html
 * request to be able to flush it on destroy
 */
function created () {
  window.addEventListener('message', this.handleMessages)
  this.loadBlocksDebounced = _debounce(this.getBlocks, 500)
}
// Func@addWindowListener

// Func@loadBlocks
/**
 * Load templates list for the group
 */
async function loadBlocks () {
  await this.getBlocks()
}
// Func@loadBlocks

// Func@destroyed
/**
 * Cleaning debounce
 */
function destroyed () {
  this.loadBlocksDebounced.flush()
  this.resetBlocks()
}
// Func@destroyed

// Vue component syntax
export default {
  name,
  props,
  data,
  methods,
  mounted: loadBlocks,
  created: created,
  destroyed: destroyed,
  computed,
  components
}
