/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Slider'

// Properties
const props = {
  default: String | Number,
  min: Number,
  max: Number,
  precision: Number,
  layout: Array,
  locked: Boolean,
  step: Number
}

// Computed methods
const computed = {
  /**
   * [inputNumberSize description]
   * @return {[type]} [description]
   */
  inputNumberSize () {
    return this.layout ? this.layout[0] : 9
  },
  /**
   * [sliderSize description]
   * @return {[type]} [description]
   */
  sliderSize () {
    return this.layout ? this.layout[1] : 15
  },
  /**
   * [value description]
   * @type {Object}
   */
  value: {
    get () {
      return (this.precision ? parseFloat(this.default) : parseInt(this.default))
    },
    set (newValue) {
      this.$emit('valueChanged', newValue)
    }
  }
}

// Vue component syntax
export default {
  name,
  props,
  computed
}
