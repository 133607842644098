export default {
  loading: false,
  openGraphMore: false,
  editionMode: true,
  deletePrompt: false,
  showDeleteConfirmation: false,
  metaOgHtml: '',
  form: {
    title: '',
    description: '',
    sitename: '',
    imagepath: ''
  },
  dataOgImgObj: {
    attributes: { dataOgImage: null },
    id: 'mj-image_og',
    tagName: 'image-og'
  }
}
