// Component states
import states from './overview.state.js'
import appState from '@/services/states/states.config.json'
import workspaceState from '@/components/parts/workspace/workspace.state.js'

// Components
import PrismEditor from 'vue-prism-editor'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import MdiCopy from 'vue-material-design-icons/ContentCopy.vue'
import MdiMailUpdate from 'vue-material-design-icons/EmailSend.vue'

// Service
import { mjmlJson, setOverview, mjmlOptimized } from '@/services/states/states.service'
import { parseTemplate } from '@/services/dnd-template/dnd-template.service'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Overview'

// Vue@subComponents
const components = {
  MdiCopy,
  PrismEditor,
  MdiMailUpdate
}
// Vue@subComponents

// Properties
const props = {
  type: String
}

// Vue@data
const data = function () {
  return {
    states
  }
}
// Vue@data

// Computed methods
const computed = {
  mjmlRenderingOption: {
    // getter
    get () {
      return mjmlOptimized().get()
    },
    // setter
    set (val) {
      mjmlOptimized().set(val)
    }
  },
  jsonData: {
    // getter
    get () {
      return appState.workspace.mjmlJson
    },
    // setter
    set (json) {
      mjmlJson().save(json)
    }
  }
}

const methods = {

  // Func@handleJsonSuccess
  /**
   * Get json file (or txt file) and replace DND JSON
   * @param  {Object} data (uploaded file data)
   */
  handleJsonSuccess (data) {
    const file = data.file
    const reader = new FileReader()

    switch (data.file.type) {
      case 'application/json':
        reader.readAsText(file)
        reader.onloadend = function () {
          const template = reader.result
          const templateObj = JSON.parse(template)
          const isDndBackup = templateObj.tagName && templateObj.tagName === 'mjml'

          if (!isDndBackup) {
            console.warn('not a template file')
            return
          }

          parseTemplate({ template })
            .toDnd()
            .then(dndTemplate => {
              workspaceState.template = Object.assign(workspaceState.template, dndTemplate)
              return dndTemplate
            })
        }
        break

      case 'text/plain': {
        reader.readAsText(file)
        reader.onloadend = function () {
          const fileData = reader.result
          const template = JSON.parse(fileData)
          const templateObj = JSON.parse(template)
          const isDndBackup = templateObj.tagName && templateObj.tagName === 'mjml'

          if (!isDndBackup) {
            console.warn('not a template file')
            return
          }

          parseTemplate({ template })
            .toDnd()
            .then(dndTemplate => {
              workspaceState.template = Object.assign(workspaceState.template, dndTemplate)
              return dndTemplate
            })
        }
        break
      }
    }
  },
  // Func@handleJsonSuccess

  // Func@updateOverview
  /**
   * Update overview and set generated html in service
   * @return {Promise}
   */
  async updateOverview () {
    const disableParsing = true
    const html = await this.api
      .mjml()
      .render(this.jsonData, disableParsing)
    setOverview(html)
  }
  // Func@updateOverview
}

// Vue component syntax
export default {
  name,
  data,
  props,
  methods,
  computed,
  components
}
