// Component states
import states from './structure-library.state.js'

// Default style conf
import componantsDefaultStyle from '@/assets/config/default-style/componants.conf'

// Components
import { Container, Draggable } from 'vue-smooth-dnd'

// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'

// Services
import { componentBuilder } from '@/services/utils/utils.service'
import { globalstates, shiftShortcuts, dndState } from '@/services/states/states.service'

// Libraries
import {
  cloneDeep as _cloneDeep,
  groupBy as _groupBy,
  get as _get,
  map as _map
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Structure-library'

// Vue@props
const props = {
  isActive: Boolean
}
// Vue@props

// Data
const data = function () {
  return {
    states,
    shortcuts,
    loaded: false,
    editPanelState: _get(globalstates, 'leftPanel.editPanel', {})
  }
}

// Vue@subComponents
const components = {
  Container,
  Draggable
}
// Vue@subComponents

// Methods
const methods = {

  shiftShortcuts: shiftShortcuts,

  /**
   * Focus seach field
   * (This will be used by shortcut)
   */
  focusSearch () {
    this.$refs['search-structure']
      .$el
      .querySelector('.el-input__inner')
      .focus()
  },

  // Func@getWidth
  /**
   * Get width from attributes or deduce it from
   * column number
   * @param  {Object} attr    (Column attributes)
   * @param  {Array} children (Columns list)
   *
   * @return {String}         (xx.xx% | width: xx.xx%;)
   */
  getWidth (attr, children, onlyValue) {
    const value = attr.width ? attr.width : `${(100 / children.length)}%`
    if (onlyValue) {
      const readOnlyValue = value.slice(0, -1)
      const hasDecimal = readOnlyValue % 1 !== 0
      return hasDecimal ? `~${Math.round(readOnlyValue)}%` : `${Math.round(readOnlyValue)}%`
    }
    return `width: ${value};`
  },
  // Func@getWidth

  /**
   * Load configuration from assets then generate MJML nodes
   * for DND modifiers
   */
  async loadConfiguration () {
    await Promise.all(this.states.items.map(async item => {
      const overwrite = {
        'mj-column': {
          attributes: {
            activeStateIndex: 0,
            states: [
              {
                default_operator: 'or',
                conditions: {},
                operations: []
              }
            ]
          },
          children: [[]]
        }
      }
      if (item.columsAttributes) {
        overwrite['list-mj-column'] = item.columsAttributes
          .map(attributes => {
            return { attributes }
          })
      }

      const data = await componentBuilder({
        withId: true,
        componentId: item.componentId,
        childrenNbr: item.childrenNbr,
        overwrite
      })
      this.$set(item, 'data', data)
    })).then(() => { this.loaded = true })
  },

  // Func@getChildPayload
  /**
   * Get payload of the current item ready to drop
   * @param  {Int}    indexGroup (Index of the group)
   * @param  {Object} Goup       (Group of structure)
   *
   * @return {Object}         (Item data)
   */
  getChildPayload (group, index) {
    const element = _cloneDeep(group.items[index])
    const rowsBgColor = componantsDefaultStyle.structures.rows['background-color']
    if (rowsBgColor) element.data.attributes['background-color'] = rowsBgColor

    element.data.children.map(column => {
      const columnsBgColor = componantsDefaultStyle.structures.columns['background-color']
      if (columnsBgColor) column.attributes['background-color'] = columnsBgColor
      return column
    })

    return element
  },
  // Func@getChildPayload

  // Func@addNewSection
  /**
   * Set intention to drag element from library (new element)
   * @param {Boolean} value
   */
  onDrag ({ isSource, payload, value, fromPreDrag }) {
    // Set drag and drop global state
    if (!isSource) return
    if (fromPreDrag) {
      this.states.dragState.anticipate = value
      if (!value && this.states.dragState.onDrag) return
    } else {
      this.states.dragState.onDrag = value
      if (!value && this.states.dragState.anticipate) return
    }
    const componentId = _get(payload, 'tagName') ? `${payload.tagName}#new` : 'mj-section#new'
    dndState({ isSource, componentId, value })
  }
  // Func@addNewSection
}

// Computed Methods
const computed = {

  // Func@groupByColumns
  /**
   * Group lybrary items by number of columns
   */
  groupByColumns () {
    const groupedItems = _groupBy(this._filteredItems, 'data.children.length')
    return _map(groupedItems, (value, key) => {
      return {
        name: this.states.groupeNames[key],
        items: value
      }
    })
  },
  // Func@groupByColumns

  // Func@filteredItems
  /**
   * Filter used with search field
   * @return {Array} (list of filtered items)
   */
  _filteredItems () {
    if (!this.loaded) return []
    return this.states.items.filter(item => {
      return item.name.indexOf(this.states.searchInput.toLowerCase()) >= 0
    })
  }
  // Func@filteredItems
}

// Vue component syntax
export default {
  name,
  data,
  props,
  methods,
  computed,
  components,
  created: methods.loadConfiguration
}
