import { render, staticRenderFns } from "./workspace.html?vue&type=template&id=acf5df98&scoped=true&external"
import script from "./workspace.script.js?vue&type=script&lang=js&external"
export * from "./workspace.script.js?vue&type=script&lang=js&external"
import style0 from "./workspace.scss?vue&type=style&index=0&id=acf5df98&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acf5df98",
  null
  
)

/* custom blocks */
import block0 from "./workspace.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fparts%2Fworkspace%2FWorkspace.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports