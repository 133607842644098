var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"structure-library",attrs:{"data-tested-as":"structure-library"}},[_c('el-input',{ref:"search-structure",staticClass:"search",attrs:{"placeholder":_vm.$t('t_search-structure_')},on:{"focus":function($event){_vm.shiftShortcuts().disable()},"blur":function($event){_vm.shiftShortcuts().enable()}},model:{value:(_vm.states.searchInput),callback:function ($$v) {_vm.$set(_vm.states, "searchInput", $$v)},expression:"states.searchInput"}},[_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"prefix"},slot:"prefix"})]),_vm._l((_vm.groupByColumns),function(group,indexGroup){return _c('div',{staticClass:"group",on:{"mouseenter":function($event){return _vm.onDrag({ isSource: true, value: true, fromPreDrag: true })},"mouseleave":function($event){return _vm.onDrag({ isSource: true, value: false, fromPreDrag: true })}}},[_c('span',{staticClass:"group-name"},[_vm._v(_vm._s(_vm.$t(group.name)))]),_c('Container',{attrs:{"group-name":"mj-section-new","behaviour":"copy","get-child-payload":function (index) { return _vm.getChildPayload(group, index) }},on:{"drag-start":function (ref) {
	var isSource = ref.isSource;
	var payload = ref.payload;

	return _vm.onDrag({ isSource: isSource, payload: payload, value: true });
},"drag-end":function (ref) {
	var isSource = ref.isSource;
	var payload = ref.payload;

	return _vm.onDrag({ isSource: isSource, payload: payload, value: false });
}}},_vm._l((group.items),function(item){return _c('Draggable',{key:item.id,attrs:{"data-tested-as":("structure-" + (item.name.split(' ').join('-')))}},[_c('div',{staticClass:"draggable-item"},[_c('ul',{staticClass:"row"},_vm._l((item.data.children),function(column){return (item.data)?_c('li',{staticClass:"column",style:(_vm.getWidth(column.attributes, item.data.children))},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.getWidth(column.attributes, item.data.children, true))+" ")])]):_vm._e()}),0)])])}),1)],1)}),(_vm.isActive && !_vm.editPanelState.active)?_c('button',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(_vm.shortcuts.general.focusSearchField.binds),expression:"shortcuts.general.focusSearchField.binds"}],staticClass:"shortcut",on:{"shortkey":function($event){return _vm.focusSearch()}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }