import { render, staticRenderFns } from "./general-panel.html?vue&type=template&id=4b19ef70&scoped=true&external"
import script from "./general-panel.script.js?vue&type=script&lang=js&external"
export * from "./general-panel.script.js?vue&type=script&lang=js&external"
import style0 from "./general-panel.scss?vue&type=style&index=0&id=4b19ef70&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b19ef70",
  null
  
)

/* custom blocks */
import block0 from "./general-panel.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fparts%2Fgeneral-panel%2FGeneral-panel.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports