// Component states
import states from './open-graph-panel.state.js'
import workspaceStates from '@/components/parts/workspace/workspace.state.js'

// Components
import MdiDelete from 'vue-material-design-icons/Delete.vue'
import MdiClose from 'vue-material-design-icons/Close.vue'
import MetaOgPreview from '@/components/standalone/meta-og-preview/Meta-og-preview.vue'
import ImagePathModifier from '@/components/standalone/image-path-modifier/Image-path-modifier.vue'

// Services
import {
  metaog,
  gotMetaOg,
  globalstates,
  closeOpenGraphPanel,
  shiftShortcuts
} from '@/services/states/states.service'
import { eventEmit } from '@/services/utils/utils.service'

// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'

// Assets
import image from '@/assets/images/requirements.svg'

// Libraries
import {
  get as _get
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Open-graph-panel'

// Vue@Properties
const props = {
  mjml: {
    type: Object,
    default: () => {
      return { attributes: {}, id: 'mj-image_og', tagName: 'image-og' }
    }
  },
  show: {
    type: Boolean,
    default: true
  }
}
// Vue@Properties

// Vue@data
const data = function () {
  return {
    image,
    states,
    shortcuts,
    openGraphPanelState: _get(globalstates, 'leftPanel.openGraphPanel', {})
  }
}
// Vue@data

// Vue@subComponents
const components = {
  MdiDelete,
  MdiClose,
  MetaOgPreview,
  ImagePathModifier
}
// Vue@subComponents

// Methods
const methods = {

  shiftShortcuts: shiftShortcuts,

  // Func@closePanel
  /**
   * closePanel
   * Set panel inactive and clean content
   */
  closePanel () {
    closeOpenGraphPanel()
  },
  // Func@closePanel

  /**
   * Generate image with correct size
   * @param {String} src (url)
   */
  _setImageProperties (src) {
    const img = new Image()
    img.src = src
  },

  // Func@save
  /**
   * Save meta og
   * @param  {String} method (put or patch)
   */
  async save () {
    if (!this.hasJwtToken) {
      console.warn('No jwt Token: Save request is disabled')
      return
    }
    try {
      this.states.loading = true
      await this.api
        .mjml(this.$route.params.id)
        .save(workspaceStates.template, this.states.form)
      metaog().set(this.states.form)
      gotMetaOg().set(true)
      eventEmit('sms-preview-saved', { isSuccess: true })
    } catch (err) {
      eventEmit('sms-preview-saved', { isSuccess: false })
      console.error(err)
    } finally {
      this.states.loading = false
    }
  },
  // Func@save

  // Func@deleteMetaOg
  async deleteMetaOg () {
    if (!this.hasJwtToken) {
      console.warn('No jwt Token: Save request is disabled')
      return
    }

    try {
      this.states.loading = true
      await this.api
        .mjml(this.$route.params.id)
        .save(workspaceStates.template, {
          title: '',
          sitename: '',
          imagepath: '',
          description: ''
        })
      this.states.dataOgImgObj.attributes.dataOgImage = ''
      gotMetaOg().set(false)
      eventEmit('sms-preview-deleted', { isSuccess: true })
    } catch (err) {
      console.error(err)
      eventEmit('sms-preview-deleted', { isSuccess: false })
    } finally {
      this.states.showDeleteConfirmation = false
      this.states.loading = false
    }
  },
  // Func@deleteMetaOg

  // Func@submitForm
  /**
   * Submit meta og
   * @param  {formName} form to submit
   * @return {Object}    (content data)
   */
  submitForm (formName) {
    this.$refs[formName]
      .validate(valid => {
        if (!valid) return false
        this.save()
      })
  }
  // Func@submitForm
}

// Computed Methods
const computed = {

  // Func@hasJwtToken
  /**
   * Check if we are able to reach API
   * @return {Boolean}
   */
  hasJwtToken () {
    return Boolean(this.api.token().get())
  },
  // Func@hasJwtToken

  // Func@metaOgExist
  /**
   * Check if meta OG are enabled
   * @returns {Boolean}
   */
  metaOgExist () {
    return gotMetaOg().get()
  },
  // Func@metaOgExist

  /**
   * OG image url
   * @return {String} (url)
   */
  imageURL () {
    return this.states.dataOgImgObj.attributes.dataOgImage
  },

  // Func@formRules
  /**
   * Form validation rules
   * @returns {Object}
   */
  formRules () {
    return  {
      title: [
        {
          required: true,
          message: this.$t('t_open-graph-error-title_'),
        },
        {
          max: 25,
          message: this.$t('t_open-graph-error-length-title_'),
          trigger: 'blur'
        }
      ],
      description: [
        {
          required: true,
          message: this.$t('t_open-graph-error-description_'),
        },
        {
          max: 200,
          message: this.$t('t_open-graph-error-length-description_'),
          trigger: 'blur'
        }
      ],
      sitename: [
        {
          required: true,
          message: this.$t('t_open-graph-error-sitename_'),
        },
        {
          max: 50,
          message: this.$t('t_open-graph-error-length-sitename_'),
          trigger: 'blur'
        }
      ],
      imagepath: [
        {
          required: true,
          message: this.$t('t_open-graph-error-imagepath_'),
        },
        {
          max: 2048,
          message: this.$t('t_open-graph-error-length-imagepath_'),
          trigger: 'blur'
        },
        {
          pattern: new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.\\(\\)~:+]*)*(\\?[;[\\]\\{\\}:&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$', 'i'),
          message: this.$t('t_open-graph-error-url-imagepath_'),
          trigger: 'blur'
        }
      ]
    }
  }
  // Func@formRules
}

// Vue@watchTemplate
const watch = {
  imageURL: function (newSrc) {
    this.states.form.imagepath = newSrc
  }
}
// Vue@watchTemplate

function mounted () {
  const metaOg = metaog().get()
  for (const key in metaOg) {
    if (metaOg[key]) {
      if (key === 'imagepath') this.states.dataOgImgObj.attributes.dataOgImage = metaOg[key]
      gotMetaOg().set(true)
    }
  }
  this.states.form = metaOg
}
// Vue component syntax
export default {
  name,
  props,
  data,
  watch,
  methods,
  computed,
  components,
  mounted
}
