import Vue from 'vue'
import axios from 'axios'
import Api from '@/services/dnd-api/dnd-api.service'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.BASE_URL || process.env.apiUrl || ''
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

const _axios = axios.create()

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error)
  }
)

// Plugin@axiosinstall
Plugin.install = function (Vue) {
  const api = new Api(_axios)
  Vue.axios = _axios
  window.axios = _axios
  Vue.api = api
  window.api = api
  Object.defineProperties(Vue.prototype, {
    api: {
      get () {
        return api
      }
    },
    $api: {
      get () {
        return api
      }
    },
    axios: {
      get () {
        return _axios
      }
    },
    $axios: {
      get () {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)
// Plugin@axiosinstall

export default Plugin
