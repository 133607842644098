export default {
  i18n: {
    focusSearchField: 't_focusSearchField_',
    switchModifier: 't_switchModifier_',
    switchPaneMode: 't_switchPaneMode_',
    displayStates: 't_displayStates_',
    workspace: 't_workspace_',
    leftpanel: 't_leftpanel_',
    validate: 't_validate_',
    general: 't_general_',
    addRow: 't_addRow_',
    resize: 't_resize_',
    close: 't_close_',
    undo: 't_undo_',
    redo: 't_redo_',
    textEditorContent: 't_textEditorContent_',
    copy: 't_copy_',
    paste: 't_paste_',
    pasteClean: 't_pasteClean_'
  }
}
