// Config
import colorsPallet from '@/assets/config/default-style/colors.conf'
import userConfig from '@/assets/config/user/user.conf'

// Services
import { globalstates } from '@/services/states/states.service'

// Libraries
import {
  get as _get,
  uniq as _uniq
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'cust-color-picker'

// Properties
const props = {
  model: String,
  defaultColor: String
}

// Vue@data
const data = function () {
  return {
    user: _get(globalstates, 'user')
  }
}
// Vue@data

// Computed Methods
const computed = {
  /**
   * Color of the border
   * @param  {String} newValue (rgb)
   *
   * @return {String}
   */
  color: {
    get () {
      return this.model === 'transparent' ? null : this.model
    },
    set (newValue) {
      const exist = Boolean(newValue)
      this.$emit('update', !exist ? this.defaultColor : newValue)
    }
  },

  /**
   * Get predifined colors and concat them
   * User theme color first then generic colors
   * @return {Array} ([ '#CCCCCC', '...', ... ])
   */
  predefine () {
    return this.user.configLoaded && userConfig.colors.length
      ? _uniq(userConfig.colors).map(c => c.toLowerCase())
      : _uniq(colorsPallet.default).map(c => c.toLowerCase())
  }
}

// Vue component syntax
export default {
  name,
  data,
  props,
  computed
}
