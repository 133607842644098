const emailHistory = `<tr><td><table
style="
  vertical-align: top;
  display: inline-block;
  text-align: left;
  font-size: 13px;
  direction: ltr;
  width: 100%;
"
role="presentation"
cellpadding="0"
cellspacing="0"
width="100%"
border="0">
<tr>
  <td
    align="center"
    class="title"
    style="
      font-size: 0px;
      padding: 10px 25px;
      word-break: break-word;
    ">
    <div
      class="text"
      style="
        font-family: Ubuntu, Helvetica, Arial, sans-serif;
        text-align: center;
        font-size: 18px;
        line-height: 1;
        color: #C1C1C1;
      ">
      This is an Exemple of custom HTML content
    </div>
  </td>
</tr>
<tr>
  <td
    class="table-container"
    align="left"
    style="
      font-size: 0px;
      padding: 10px 25px;
      word-break: break-word;
    ">
    <table
      cellpadding="0"
      cellspacing="0"
      class="table"
      width="100%"
      border="0"
      style="
        cellspacing: 0;
        color: #000000;
        font-family: Ubuntu, Helvetica, Arial, sans-serif;
        font-size: 13px;
        line-height: 20px;
        table-layout: auto;
        width: 100%;
      ">
      <tr class="header" style="
        border-bottom: 1px solid #ecedee;
        text-align: left;
        padding: 15px 0;
      ">
        <th class="years" style="padding: 5px 15px 0 0; width: 50px;">Years</th>
        <th class="desc" style="padding: 5px 15px 0 0;">Description</th>
      </tr>
      <tr class="content">
        <td class="years" style="padding: 5px 15px 0 0; line-height: 16px;">
          1969
        </td>
        <td class="desc" style="padding: 5px 15px 0 0; line-height: 16px; font-weight: 400;">
          The US Department of Defense implemented ARPANET.
        </td>
      </tr>
      <tr class="content">
        <td class="years" style="padding: 5px 15px 0 0; line-height: 16px;">
          1971
        </td>
        <td class="desc" style="padding: 5px 15px 0 0; line-height: 16px; font-weight: 400;">
          Ray Tomlinson invented and sent the first mail on ARPANET.
        </td>
      </tr>
      <tr class="content">
        <td class="years" style="padding: 5px 15px 0 0; line-height: 16px;">
          1988
        </td>
        <td class="desc" style="padding: 5px 15px 0 0; line-height: 16px; font-weight: 400;">
          First commercial email client, Microsoft Mail is released for Mac.
        </td>
      </tr>
      <tr class="content">
        <td class="years" style="padding: 5px 15px 0 0; line-height: 16px;">
          1990
        </td>
        <td class="desc" style="padding: 5px 15px 0 0; line-height: 16px; font-weight: 400;">
          Introduced us to HTML emails taking over plain text emails.
        </td>
      </tr>
      <tr class="content">
        <td class="years" style="padding: 5px 15px 0 0; line-height: 16px;">
          1992
        </td>
        <td class="desc" style="padding: 5px 15px 0 0; line-height: 16px; font-weight: 400;">
          The attachment is born with the Mime protocol (Multipurpose Internet Mail Extensions)
        </td>
      </tr>
      <tr class="content">
        <td class="years" style="padding: 5px 15px 0 0; line-height: 16px;">
          2009
        </td>
        <td class="desc" style="padding: 5px 15px 0 0; line-height: 16px; font-weight: 400;">
          Responsive emails were introduced and 'embedded video' was first used.
        </td>
      </tr>
      <tr class="content">
        <td class="years" style="padding: 5px 15px 0 0; line-height: 16px;">
          2014
        </td>
        <td class="desc" style="padding: 5px 15px 0 0; line-height: 16px; font-weight: 400;">
          Integration of CSS along with HTML brought in the era of Interactive emails.
        </td>
      </tr>
    </table>
  </td>
</tr>
</table></td></tr>`

export default emailHistory
