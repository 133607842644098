// Libraries
import {
  capitalize as _capitalize
} from 'lodash-es'

class History {
  constructor ({ maxSize = 5 }) {
    this.list = new Map()
    this.callBacks = {}
    this.conf = {
      maxSize
    }
    this.iterators = {
      pos: 0,
      undoable: 0,
      redoable: 0
    }
  }

  // Func@_iterate
  /**
   * Iterator engine
   * @return {Function} (incr and decr)
   */
  _iterate () {
    return {
      /**
       * Increment iterators depending configuration
       * @return {Integer} (history position)
       */
      incr: ({ add } = {}) => {
        const i = this.iterators.pos
        // If no stack to redo and no new stack to save, return current position
        if (!this.iterators.redoable && !add) return i
        // if max stacks position is reach, save from the start of the table
        this.iterators.pos = i === this.conf.maxSize ? 0 : i + 1
        // If max stacks of undo not reach, increment it on add
        if (this.iterators.undoable < this.conf.maxSize) this.iterators.undoable++
        // If stacks of redo not empty, decrement it on add
        if (this.iterators.redoable > 0) this.iterators.redoable--

        return this.iterators.pos
      },
      /**
       * Decrement iterators depending configuration
       * @return {Integer} (history position)
       */
      decr: () => {
        const i = this.iterators.pos
        // If no stack to undo, return current position
        if (!this.iterators.undoable) return i
        // if min stacks position is reach, save from the end of the table
        this.iterators.pos = i === 0 ? this.conf.maxSize : i - 1
        // If max stacks of redo not reached, increment it
        if (this.iterators.redoable < this.conf.maxSize) this.iterators.redoable++
        // Decrement undo stacks
        this.iterators.undoable--

        return this.iterators.pos
      }
    }
  }
  // Func@_iterate

  // Func@_setCallback
  /**
   * Private method to set events call back functions
   * @param {String}   key (Event name)
   * @param {Function} cb  (callback)
   */
  _setCallback (key, cb) {
    if (typeof cb !== 'function') {
      console.warn(`History service: Wrong parameters for on${_capitalize(key)}, must be a function`)
    }

    this.callBacks[key] = cb
  }
  // Func@_setCallback

  // Func@init
  /**
   * Save the default state
   * @param  {Object} value (MJML)
   */
  init (value) {
    // Set starting value
    this.list.set(0, JSON.stringify(value))
    // Trigger callback
    if (this.callBacks.init) this.callBacks.init(JSON.stringify(value))
  }
  // Func@init

  // Func@onInit
  /**
   * Set function to call back on init
   * @param  {Function} cb (callback)
   */
  onInit (cb) {
    this._setCallback('init', cb)
  }
  // Func@onInit

  // Func@add
  /**
   * Add new value to the history list
   * @param {Object} value (MJML)
   */
  add (value) {
    // Get the position to add a stack
    const pos = this._iterate().incr({ add: true })
    // Add the stack
    this.list.set(pos, JSON.stringify(value))
    // Trigger callback
    if (this.callBacks.add) this.callBacks.add(JSON.stringify(value))
  }
  // Func@add

  // Func@onAdd
  /**
   * Set function to call back on add
   * @param  {Function} cb (callback)
   */
  onAdd (cb) {
    this._setCallback('add', cb)
  }
  // Func@onAdd

  // Func@getUndo
  /**
   * Get previous history state if exist
   * or current one if no exist
   *
   * @return {Object} (exist: undo exist, value: MJML)
   */
  getUndo () {
    // Save the position
    const oldPos = this.iterators.pos
    // Get previous position
    const pos = this._iterate().decr()
    // Trigger callback
    if (this.callBacks.undo) this.callBacks.undo()
    // Return undo exist and associated stack
    return {
      exist: pos !== oldPos,
      value: JSON.parse(this.list.get(pos))
    }
  }
  // Func@getUndo

  // Func@onUndo
  /**
   * Set function to call back on undo
   * @param  {Function} cb (callback)
   */
  onUndo (cb) {
    this._setCallback('undo', cb)
  }
  // Func@onUndo

  // Func@getRedo
  /**
   * Get next history state if exist
   * or current one if no exist
   *
   * @return {Object} (exist: redo exist, value: MJML)
   */
  getRedo () {
    // Save the position
    const oldPos = this.iterators.pos
    // Get previous position
    const pos = this._iterate().incr()
    // Trigger callback
    if (this.callBacks.redo) this.callBacks.redo()
    // Return redo exist and associated stack
    return {
      exist: pos !== oldPos,
      value: JSON.parse(this.list.get(pos))
    }
  }
  // Func@getRedo

  // Func@onRedo
  /**
   * Set function to call back on redo
   * @param  {Function} cb (callback)
   */
  onRedo (cb) {
    this._setCallback('redo', cb)
  }
  // Func@onRedo

  // Func@getStartingDefaultStyle
  getStartingDefaultStyle () {
    return JSON.parse(this.list.get(0))
  }
  // Func@getStartingDefaultStyle
}

export default History
