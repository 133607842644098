// Component states
import states from './deep-edit-panel.state.js'

// Components
import ComponentLoader from '@/components/standalone/component-loader/Component-loader.vue'

// Services
import { globalstates, closeEditPanel, lockEditPanel } from '@/services/states/states.service'

// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'

// Library
import {
  map as _map,
  get as _get,
  drop as _drop,
  cloneDeep as _cloneDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Deep-edit-panel'

// Vue@data
const data = function () {
  return {
    states,
    shortcuts,
    edit: _get(globalstates, 'leftPanel.deepEditPanel', {})
  }
}
// Vue@data

// Vue@subComponents
const components = {
  ComponentLoader
}
// Vue@subComponents

// Methods
const methods = {

  // Func@closePanel
  /**
   * closePanel
   * Set panel inactive and clean content
   */
  closePanel () {
    closeEditPanel({ isModal: true })
  },
  // Func@closePanel

  /**
   * [enrichedModifierConf description]
   * @param  {Object} modifier [description]
   *
   * @return {Object}          [description]
   */
  enrichedModifierConf (modifier) {
    const modifierClone = _cloneDeep(modifier)
    const parentDataShared = _get(this.edit, 'parent')
    const shareAllParentAttributes = _get(modifier, 'conf.shareParentAttributes')
    const parentAttributesRequested = _get(modifier, 'conf.parentAttr')
    const modifierOpt = _get(this.edit, 'modifiers')

    let conf = {
      stateIndex: this.stateIndex,
      isDeepEdit: true
    }

    // Detect if parent attr is requested
    if (shareAllParentAttributes && parentDataShared) conf.parent = parentDataShared.mjml.attributes
    else if (parentAttributesRequested && parentDataShared) {
      conf.parent = {}
      let key
      parentAttributesRequested.forEach(attrKey => {
        switch (attrKey) {
          case 'width':
            key = parentDataShared.mjml.attributes[attrKey] === ''
              ? `${100 / parentDataShared.columns}%`
              : parentDataShared.mjml.attributes[attrKey]
            break
          default:
            key = parentDataShared.mjml.attributes[attrKey]
        }
        conf.parent[attrKey] = key
      })
    }

    const updatedconf = Object.assign(conf, modifierClone.conf)
    if (_get(updatedconf, 'keepSync.customStates')) updatedconf.keepSync.components = modifierOpt
    if (updatedconf.parentAttr) delete updatedconf.parentAttr

    return updatedconf
  },

  // Func@loadConfiguration
  /**
   * Load MJML element component configuration on demand
   */
  async loadConfiguration (tagName) {
    if (!this.content) return
    const resource = tagName
      ? `${tagName}.conf`
      : `${_get(this.content, 'tagName', 'unknow')}.conf`

    try {
      if (this.isDynamic) this.states.components.stateActive = `state-${this.content.attributes.activeStateIndex}`
      const module = await import(/* webpackMode: "eager" */'@/assets/config/mjml-components/' + resource + '.json')
      const conf = { ...module } // module to obj
      const isMultiConf = _get(conf, 'sub_components', []).length && conf.type === 'relational'

      // Check if the component have dnd componentId and commute modifiers when needed.
      // A single MJML component can be used for multiple dnd component
      // exemple with mj-image which is used for image or qrcode dnd component but
      // with different configuration
      let dndComponentId
      const contentClass = _get(this.content, 'attributes.css-class', '')
      const dndComponentIdClass = contentClass.split(' ').find(c => c.includes('dcid_'))
      if (dndComponentIdClass) dndComponentId = dndComponentIdClass.split('dcid_').pop()
      if (conf.modifiers) {
        const modifiers = conf.modifiers[dndComponentId] || conf.modifiers.default
        conf.modifiers = modifiers
        if (!this.states.components.active && _get(conf, 'modifiers.length')) {
          const index = this.states.modifiers.defaultIndex[conf.type]
          this.states.components.active = `${modifiers[index].title}_${index}`
        }
      }

      this.states.confs.push(conf)

      if (isMultiConf) {
        const components = conf.sub_components
        Promise.all(components.map(async componentId => await this.loadConfiguration(componentId)))
      }
    } catch (err) {
      console.error(err)
    }
  },
  // Func@loadConfiguration

  // Func@currentContent
  /**
   * Return the current content
   * @param  {Integer} indexConf (Index of the content)
   * @return {Object}            (Current content)
   */
  currentContent (indexConf) {
    return indexConf > 0 ? this.content.children[indexConf - 1] : this.content
  }
  // Func@currentContent
}

// Computed Methods
const computed = {

  // Func@content
  /**
   * Current content to edit (can be structure or contents app)
   * @return {Object} (mjml object)
   */
  content () {
    return _get(this.edit, `contents[${this.edit.index}]`)
  },
  // Func@content

  // Func@stateIndex
  /**
   * Current state index (dynamic contents)
   * @return {Integer}   (state)
   */
  stateIndex () {
    const state = _get(this.states.components, 'stateActive')
    return state ? state.split('-')[1] : 0
  },
  // Func@stateIndex

  // Func@isDynamic
  /**
   * Check if it's dynamic content
   * @return {Boolean}
   */
  isDynamic () {
    return Boolean(this.content.attributes.states)
  }
  // Func@isDynamic
}

// Whatched properties
const watch = {

  // Vue@watchContent
  /**
   * Load configuration on content update
   * @param  {Object} newVal (new content)
   * @param  {Object} oldVal (previous content)
   */
  content: function (newVal, oldVal) {
    if (_get(newVal, 'id') === _get(oldVal, 'id')) return
    this.loadConfiguration()
  },
  // Vue@watchContent

  // Vue@watchStateActive
  /**
   * Switch data corresponding to the selected state
   * @param  {Object} newVal (new state name)
   * @param  {Object} oldVal (old state name)
   */
  'states.components.stateActive': function (newVal, oldVal) {
    if (newVal === oldVal) return
    this.content.attributes.activeStateIndex = this.stateIndex
  },
  // Vue@watchStateActive

  // Vue@watchAttributesStates
  /**
   * Switch data corresponding to the selected state
   * @param  {Object} newVal (new state name)
   */
  'content.attributes.states': {
    handler (newVal) {
      const stateIndexError = _drop(_map(newVal, 'operations.length')).indexOf(0)
      const hasEmptyState = stateIndexError !== -1

      if (hasEmptyState) this.states.components.stateIndexError = stateIndexError + 1
      lockEditPanel(hasEmptyState, 'state')
    },
    deep: true
  }
  // Vue@watchAttributesStates
}

// Func@loadConfiguration
/**
 * Load edit panel configuration depending MJML tag and
 * set first component as open by default
 */
function loadConfiguration () {
  this.states.confs = []
  this.loadConfiguration()
}
// Func@loadConfiguration

// Vue component syntax
export default {
  name,
  data,
  watch,
  methods,
  computed,
  components,
  beforeMount: loadConfiguration
}
