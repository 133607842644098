export default {
  conf: {},
  modifiers: {
    defaultIndex: {
      content: 0,
      structure: 0
    }
  },
  errorMessages: {
    state: 't_state-error_',
    structure: 't_structure-error_',
    incomplete: 't_incomplete-error_',
    wrong_url: 't_wrong-url-error_'
  },
  components: {
    stateActive: 'state-0',
    stateIndexError: null,
    stateIndex: 0,
    active: null
  }
}
