// Component states
import states from './general-panel.state.js'

// Components
import SettingsGeneralModifier from '@/components/standalone/settings-general-modifier/Settings-general-modifier.vue'
import DefaultTemplateModifier from '@/components/standalone/default-template-modifier/Default-template-modifier.vue'
import ShortcutsModifier from '@/components/standalone/shortcuts-modifier/Shortcuts-modifier.vue'
import OpengraphModifier from '@/components/standalone/opengraph-modifier/Opengraph-modifier.vue'

// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'

// Services
import { landingPageMode } from '@/services/states/states.service'

// Library
import {
  get as _get,
  remove as _remove,
  findIndex as _findIndex,
  clone as _clone
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'General-panel'

// Data
const data = function () {
  return {
    states,
    shortcuts
  }
}

// Vue@subComponents
const components = {
  DefaultTemplateModifier,
  SettingsGeneralModifier,
  ShortcutsModifier,
  OpengraphModifier
}
// Vue@subComponents

// Methods
const methods = {
  // Func@switchModifier
  /**
   * Switch pane mode (General/Structure/Contents)
   */
  switchModifier () {
    const modifiers = this.states.sections
    const index = _findIndex(modifiers, { id: this.states.active })
    const nextModifier = _get(modifiers, `${index + 1}.id`, modifiers[0].id)

    this.$set(states, 'active', nextModifier)
  }
  // Func@switchModifier
}

// Func@mounted
function mounted () {
  // Add aria label for Sentry
  this.$nextTick(function () {
    for (const section of this.$refs.sections) {
      section.$el
        .querySelector('.el-collapse-item__header')
        .setAttribute('aria-label', section.$el.getAttribute('aria-label'))
    }
  })

  // Open Graph panel only on landing page mode
  this.states.activeSections = _clone(this.states.sections, true)
  if (!landingPageMode().get()) {
    this.states.activeSections = _remove(this.states.activeSections, (elt) => {
      return elt.id !== 'opengraph'
    })
  }
}

// Vue component syntax
export default {
  name,
  data,
  methods,
  components,
  mounted
}
