export default {
  deletePrompt: false,
  loading: false,
  metaog: {
    title: '',
    description: '',
    sitename: '',
    imagepath: ''
  }
}
