// Component states
import states from './content-panel.state.js'

// Conf
import emailHistoryHtml from './email-history.const.js'

// Default style conf
import textDefaultStyle from '@/assets/config/default-style/text.conf'
import componentsDefaultStyle from '@/assets/config/default-style/componants.conf'

import mjText from '@/assets/config/mjml-components/mj-text.conf'
import mjButton from '@/assets/config/mjml-components/mj-button.conf'
import mjAccordion from '@/assets/config/mjml-components/mj-accordion.conf'

// Components
import { Container, Draggable } from 'vue-smooth-dnd'
import MdiChat from 'vue-material-design-icons/Chat.vue'
import MdiTable from 'vue-material-design-icons/Table.vue'
import MdiImage from 'vue-material-design-icons/Image.vue'
import MdiMinus from 'vue-material-design-icons/Minus.vue'
import MdiText from 'vue-material-design-icons/FormatText.vue'
import MdiChartBar from 'vue-material-design-icons/ChartBar.vue'
import MdiMenuOpen from 'vue-material-design-icons/MenuOpen.vue'
import MdiCursorPointer from 'vue-material-design-icons/CursorPointer.vue'
import MdiImageMultiple from 'vue-material-design-icons/ViewCarousel.vue'
import MdiCodeTags from 'vue-material-design-icons/CodeTags.vue'
import MdiArrowSplitHorizontal from 'vue-material-design-icons/ArrowSplitHorizontal.vue'
import MdiMenu from 'vue-material-design-icons/Menu.vue'
import MdiMenuSwap from 'vue-material-design-icons/MenuSwap.vue'
import MdiEraser from 'vue-material-design-icons/Eraser.vue'
import MdiQrcode from 'vue-material-design-icons/Qrcode.vue'
import MdiPlayBox from 'vue-material-design-icons/PlayBox.vue'

// Services
import { componentBuilder } from '@/services/utils/utils.service'
import { globalstates, dndState } from '@/services/states/states.service'

// Library
import {
  map as _map,
  get as _get,
  groupBy as _groupBy
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Content-panel'

// Vue@props
const props = {
  activePanel: String
}
// Vue@props

// Data
const data = function () {
  return {
    states,
    globalstates: globalstates,
    defaultConfigs: {
      textDefaultStyle,
      componentsDefaultStyle
    },
    conf: {
      mjText,
      mjButton,
      mjAccordion
    }
  }
}

// Vue@subComponents
const components = {
  MdiChat,
  MdiText,
  MdiTable,
  MdiImage,
  MdiMinus,
  Container,
  Draggable,
  MdiCodeTags,
  MdiChartBar,
  MdiMenuOpen,
  MdiImageMultiple,
  MdiCursorPointer,
  MdiArrowSplitHorizontal,
  MdiMenuSwap,
  MdiMenu,
  MdiEraser,
  MdiQrcode,
  MdiPlayBox
}
// Vue@subComponents

// Methods
const methods = {
  // Func@getChildPayload
  /**
   * To get content payload
   * @param  {Int} index (data index)
   * @return {Object}    (content data)
   */
  getChildPayload (index, groupIndex) {
    return this.contents[groupIndex].items[index]
  },
  // Func@getChildPayload

  // Func@updateDndState
  /**
   * Set drag & drop state for all the workspace,
   * this func is triggered by all drag & drop container
   * whatever the source of the drag
   *
   * @param  {Boolean} options.isSource  (to identify if the the trigger is the source)
   * @param  {Object}  options.payload   (data when new element on drag)
   * @param  {Boolean} options.value     (is drag active ? or drag end ?)
   */
  updateDndState ({ isSource, payload, value }) {
    // Set drag and drop global state
    if (!isSource) return
    const componentId = _get(payload, 'tagName') ? `${payload.tagName}#new` : 'mj-content#new'
    dndState({ isSource, componentId, value })
  },
  // Func@updateDndState

  // Func@getContentClass
  /**
   * Get content class and class 'soon' for next content
   * @param  {Object} item (content data)
   * @return {String}      (class)
   */
  getContentClass (item) {
    const toDisable = item.consumable && !(item.consumable - (this.globalstates.componentsInventory[item.componentId] || 0))
    return `${item.componentId} ${item.state === 'soon' ? item.state : ''} ${toDisable ? 'disabled' : ''}`
  },
  // Func@getContentClass

  // Func@loadConfiguration
  /**
   * Load content panel configuration depending on MJML tag and
   * set first component as open by default
   */
  async loadConfiguration () {
    this.states.activeItems = this.states.items.filter(c => {
      return !c.checkActive || c.checkActive().get()
    })

    await Promise.all(this.states.activeItems.map(async item => {
      switch (item.componentId) {
        case 'mj-raw': {
          item.content = emailHistoryHtml
          break
        }
        case 'mj-text': {
          // eslint-disable-next-line
          const { direction, ...textDefault } = this.defaultConfigs.textDefaultStyle.text // Tricks to remove direction
          Object.assign(this.conf.mjText.attributes.default, textDefault)
          break
        }
        case 'mj-button': {
          const buttonDefault = this.defaultConfigs.componentsDefaultStyle.buttons
          Object.assign(this.conf.mjButton.attributes.default, buttonDefault)
          break
        }
        case 'mj-accordion': {
          const { direction } = this.defaultConfigs.textDefaultStyle.text

          Object.assign(this.conf.mjAccordion.attributes.default, { 
            'css-class': `is-${direction}`,
            'icon-position': direction === 'rtl' ? 'left' : 'right'
          })
          break
        }
      }

      const data = await componentBuilder({
        componentId: item.componentId,
        childrenNbr: item.childrenNbr,
        content: item.content,
        withId: true
      })
      this.$set(item, 'data', data)
    }))
  }
  // Func@loadConfiguration
}

// Computed
const computed = {
  contents () {
    const groupedItems = _groupBy(this.states.activeItems, 'type')
    return _map(groupedItems, (value, key) => {
      return {
        name: this.states.sections[key],
        items: value
      }
    })
  }
}

// Before mount

// Vue@watchTemplate
// Whatched properties
const watch = {

  // Vue@watchContent
  /**
   * Refresh configuration on panel update
   * in order to take latest default params
   */
  activePanel: {
    immediate: true,
    async handler (newVal) {
      if (newVal !== 'content') return
      await this.loadConfiguration()
    }
  },

  defaultConfigs: {
    deep: true,
    async handler () {
      await this.loadConfiguration()
    }
  }
  // Vue@watchContent
}
// Vue@watchTemplate

// Vue component syntax
export default {
  name,
  data,
  props,
  watch,
  methods,
  computed,
  components
}
