import {
  get as _get
} from "lodash-es"


/**
 * On structural components update, it's necessary to ensure retro
 * compatibility with previous versions of the DND backup file (MJML format).
 * This service help to do that smoother, old backup files are updated then saved
 * with the new version.
 */

const mappingToDnd = {
  mjml: {
    'mj-carousel': {
      tagName: 'mj-carousel-v2'
    },
    'mj-carousel-image': {
      tagName: 'mj-carousel-image-v2'
    }
  },
  ckeditorFragments: [
    {
      old: '<span class="dnd">',
      new: '<span class="dnd" dir="ltr">'
    }
  ]
}

/**
 * Apply new compatibility rules
 * @param {Object} components
 * @returns Object
 */
function checkRetroCompatibility (components) {
  const hasMjmlMapping = mappingToDnd.mjml[components.tagName]
  const hasChildren = _get(components, 'children', []).length

  // update children component
  if (hasChildren) components.children.forEach(child => checkRetroCompatibility(child))

  // Update current component
  if (hasMjmlMapping) components = Object.assign(components, mappingToDnd[components.tagName])

  // Update content format (managed by ckeditor lib)
  if (components.content) {
    mappingToDnd.ckeditorFragments.forEach(fragment => {
      if (!components.content.includes(fragment.old)) return
      components.content = components.content.replaceAll(fragment.old, fragment.new)
    })
  }
  return components
}

export default checkRetroCompatibility
