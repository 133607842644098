export default {
  widthAuto: false,
  tempWidth: null,
  bgColors: [
    {
      name: 't_row_',
      keys: {
        config: 'mjSection',
        defaultColor: null,
        attribute: 'background-color'
      }
    },
    {
      name: 't_column_',
      keys: {
        config: 'mjColumn',
        defaultColor: null,
        attribute: 'background-color'
      }
    },
    {
      name: 't_button_',
      keys: {
        config: 'mjButton',
        defaultColor: '#414141',
        attribute: 'background-color'
      }
    }
  ],
  fontColors: [
    {
      name: 't_general_',
      keys: {
        config: 'mjText',
        attribute: 'color',
        defaultColor: '#000000'
      }
    },
    {
      name: 't_button_',
      keys: {
        defaultColor: '#FFFFFF',
        config: 'mjButton',
        attribute: 'color'
      }
    }
  ]
}
