// Services
import { globalstates } from '@/services/states/states.service'

// Library
import {
  get as _get
} from 'lodash-es'

export default {
  leftPanels: [
    // TODO: to remove after feature (#640) validation
    // {
    //   name: 'general',
    //   icon: 'mdi-settings',
    //   translationKey: 't_general_',
    //   partComponent: 'general-panel'
    // },
    {
      name: 'structure',
      icon: 'mdi-view-quilt',
      translationKey: 't_structure_',
      partComponent: 'structure-panel'
    },
    {
      name: 'content',
      icon: 'mdi-headline',
      translationKey: 't_contents_',
      partComponent: 'content-panel'
    }
  ],
  leftPanel: _get(globalstates, 'leftPanel', {}),
  edit: {
    active: false,
    contents: {}
  },
  displayMjmlRendering: false,
  activeViews: {
    left: 'dnde',
    right: 'email'
  }
}
