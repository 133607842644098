// Component states
import states from './settings-general-modifier.state.js'
import workspaceStates from '@/components/parts/workspace/workspace.state.js'
import globalstates from '@/services/states/states.config.json'

// Components
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'
import SliderModifier from '../slider-modifier/Slider-modifier.vue'
// import MdiFormatBold from 'vue-material-design-icons/FormatBold.vue'
// import MdiFormatStrikethrough from 'vue-material-design-icons/FormatStrikethrough.vue'
// import MdiFormatUnderline from 'vue-material-design-icons/FormatUnderline.vue'
// import MdiFormatItalic from 'vue-material-design-icons/FormatItalic.vue'

// Services
import {
  readingDirection,
  landingPageMode
} from '@/services/states/states.service'

// Config files
// import textDefaultStyle from '@/assets/config/default-style/text.conf'
// import mjButton from '@/assets/config/mjml-components/mj-button.conf'
import mjSection from '@/assets/config/mjml-components/mj-section.conf'
// import mjColumn from '@/assets/config/mjml-components/mj-column.conf'
// import mjText from '@/assets/config/mjml-components/mj-text.conf'

// Libraries
import {
  get as _get,
  cloneDeep as _cloneDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-general'

// Vue@Components
const components = {
  SliderModifier,
  custColorPicker
  // MdiFormatBold,
  // MdiFormatStrikethrough,
  // MdiFormatUnderline,
  // MdiFormatItalic
}
// Vue@Components

// Vue@data
const data = function () {
  return {
    states: _cloneDeep(states),
    workspaceStates,
    conf: {
      // mjText,
      // mjButton,
      // mjColumn,
      mjSection
      // textDefaultStyle
    }
  }
}
// Vue@data

// Computed Methods
const computed = {

  // Func@templateMaxWidth
  /**
   * Max width depending mode
   * @return {Integer} [description]
   */
  templateMaxWidth () {
    return landingPageMode().get() ? 2000 : 1000
  },
  // Func@templateMaxWidth

  // Func@attributes
  /**
   * Templates body attributes
   * @return {Object} (MJML mj-body attributes)
   */
  attributes () {
    return _get(this.workspaceStates, 'template.children[0].attributes', {})
  },
  // Func@attributes

  /**
   * Define the default reading direction
   * @type {Getter/Setter}
   */
  defaultReadingDirection: {
    // Getter
    get () {
      return readingDirection().get() === 'rtl'
    },
    // Setter
    set (active) {
      readingDirection().set(active ? 'rtl' : 'ltr')
    }
  },

  // Func@width
  /**
   * MJML manual Width
   */
  width: {
    // getter
    get () {
      if (this.attributes.width !== '100%') {
        this.states.tempWidth = this.attributes.width
      }
      const width = this.states.widthAuto
        ? this.states.tempWidth
        : this.attributes.width

      return parseInt(width, 10)
    },
    // setter
    set (newValue) {
      this.attributes.width = `${newValue}px`
    }
  },
  // Func@width

  // Func@isOpenGrahOpen
  /**
   * Check if the openGrahPanel is open
   */
  isOpenGrahOpen () {
    return globalstates.leftPanel.openGraphPanel.active
  }
  // Func@isOpenGrahOpen
}

// Vue component syntax
export default {
  name,
  data,
  computed,
  components
}
