import { render, staticRenderFns } from "./deep-edit-panel.html?vue&type=template&id=63c6bf0e&scoped=true&external"
import script from "./deep-edit-panel.script.js?vue&type=script&lang=js&external"
export * from "./deep-edit-panel.script.js?vue&type=script&lang=js&external"
import style0 from "./deep-edit-panel.scss?vue&type=style&index=0&id=63c6bf0e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63c6bf0e",
  null
  
)

/* custom blocks */
import block0 from "./deep-edit-panel.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fparts%2Fdeep-edit-panel%2FDeep-edit-panel.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports