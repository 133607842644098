// Component states
import states from './shortcuts-modifier.state.js'

// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-text'

// Vue@data
const data = function () {
  return {
    states
  }
}
// Vue@data

// Methods
const methods = {

  // Func@keys
  /**
   * Get list of keys from shortcuts
   * @return {Array}
   */
  keys () {
    return Object.keys(shortcuts)
  },
  // Func@keys

  // Func@deepKeys
  /**
   * [deepKeys description]
   * @param  {[type]} key [description]
   * @return {[type]}     [description]
   */
  deepKeys (key) {
    return Object.entries(shortcuts[key])
  }
  // Func@deepKeys

}

// Vue component syntax
export default {
  name,
  data,
  methods
}
