// Component states
import states from './opengraph-modifier.state.js'
import workspaceStates from '@/components/parts/workspace/workspace.state.js'

// Components
import MetaOgPreview from '@/components/standalone/meta-og-preview/Meta-og-preview.vue'

// Config file
import { openOpenGraphPanel, metaog } from '@/services/states/states.service'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'opengraph-modifier'

// Vue@data
const data = function () {
  return {
    states,
    workspaceStates
  }
}
// Vue@data

// Vue@subComponents
const components = {
  MetaOgPreview
}
// Vue@subComponents

// Methods
const methods = {

  // Func@openOpenGraph
  /**
   * Open open Graph panel
   */
  openOpenGraph: openOpenGraphPanel,
  // Func@openOpenGraph

  // Func@deletePrompt
  /**
   * Delete warning meta og
   */
  deleteOg () {
    this.states.deletePrompt = false

    if (!this.hasJwtToken) {
      console.warn('No jwt Token: Save request is disabled')
      return
    }

    try {
      this.states.loading = true
      this.api
        .mjml(this.$route.params.id)
        .save(workspaceStates.template, {
          title: '',
          sitename: '',
          imagepath: '',
          description: ''
        })
    } finally {
      this.states.loading = false
    }
  }
  // Func@deletePrompt
}

const computed = {

  // Func@hasJwtToken
  /**
   * Check if we are able to reach API
   * @return {Boolean}
   */
  hasJwtToken () {
    return Boolean(this.api.token().get())
  },
  // Func@hasJwtToken

  // Func@metaOg
  /**
   * Get open graph data
   */
  metaOg () {
    return metaog().get()
  },
  // Func@metaOg

  /**
   * Get open gaphe state
   * @return {Boolean}
   */
  openGraphExist () {
    return this.metaOg.title ||
           this.metaOg.sitename ||
           this.metaOg.description ||
           this.metaOg.imagepath
  }
}

// Vue component syntax
export default {
  name,
  data,
  methods,
  computed,
  components
}
