const isMacOs = navigator.platform === 'MacIntel'

export default {
  general: {
    close: {
      binds: ['esc']
    },
    focusSearchField: {
      binds: isMacOs ? ['meta', 'shift', 'f'] : ['ctrl', 'shift', 'f']
    },
    validate: {
      binds: ['shift', 'enter']
    }
  },
  textEditorContent: {
    copy: {
      binds: isMacOs ? ['meta', 'c'] : ['ctrl', 'c']
    },
    paste: {
      binds: isMacOs ? ['meta', 'v'] : ['ctrl', 'v']
    },
    pasteClean: {
      binds: isMacOs ? ['meta', 'shift', 'v'] : ['ctrl', 'shift', 'v']
    }
  },
  leftpanel: {
    switchModifier: {
      binds: isMacOs ? ['meta', 'shift', 'e'] : ['ctrl', 'shift', 'q']
    },
    switchPaneMode: {
      binds: isMacOs ? ['meta', 'shift', 'a'] : ['ctrl', 'shift', 'a']
    }
  },
  workspace: {
    undo: {
      binds: isMacOs ? ['meta', 'z'] : ['ctrl', 'z']
    },
    redo: {
      binds: isMacOs ? ['meta', 'y'] : ['ctrl', 'y']
    },
    displayStates: {
      binds: ['shift', 'e']
    },
    resize: {
      binds: isMacOs ? ['meta', 'shift', 'r'] : ['ctrl', 'shift', 'r']
    },
    addRow: {
      type: 'multiple',
      var: 'num',
      values: [1, 2, 3, 4, 5, 6],
      binds: isMacOs ? ['meta', 'shift', 'num'] : ['ctrl', 'shift', 'num']
    }
  }
}
