// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Meta-og-preview'

// Pros
const props = {
  title: {
    type: String,
    required: true
  },
  sitename: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  },
  imagepath: {
    type: String,
    required: true
  }
}

// Vue@data
const data = function () {
  return {
    shortcuts
  }
}
// Vue@data

// Vue@subComponents

// Vue component syntax
export default {
  name,
  props,
  data
}
