import Vue from 'vue'
import {
  Autocomplete,
  CollapseItem,
  DropdownMenu,
  DropdownItem,
  InputNumber,
  ButtonGroup,
  ColorPicker,
  Container,
  Dropdown,
  Collapse,
  Loading,
  TabPane,
  Tooltip,
  Button,
  Radio,
  Select,
  Switch,
  Option,
  Upload,
  Header,
  Slider,
  Badge,
  Aside,
  Input,
  Alert,
  Card,
  Tabs,
  Main,
  Col,
  Tag,
  Row,
  Form,
  FormItem,
  Checkbox,
  Dialog
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

locale.use(lang)

Vue.use(CollapseItem)
Vue.use(Autocomplete)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(ButtonGroup)
Vue.use(InputNumber)
Vue.use(ColorPicker)
Vue.use(Container)
Vue.use(Dropdown)
Vue.use(Collapse)
Vue.use(Loading)
Vue.use(Tooltip)
Vue.use(TabPane)
Vue.use(Header)
Vue.use(Select)
Vue.use(Option)
Vue.use(Upload)
Vue.use(Slider)
Vue.use(Button)
Vue.use(Radio)
Vue.use(Switch)
Vue.use(Badge)
Vue.use(Aside)
Vue.use(Input)
Vue.use(Alert)
Vue.use(Card)
Vue.use(Tabs)
Vue.use(Main)
Vue.use(Col)
Vue.use(Row)
Vue.use(Tag)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Checkbox)
Vue.use(Dialog)
