/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'App'

// Func@addAuthorizationToken
/**
 * Add Authorization Token if existing
 */
function addAuthorizationToken () {
  if (this.$route.query.t) this.api.token().update(this.$route.query.t)
}
// Func@addAuthorizationToken

// Vue component syntax
export default {
  name,
  created: addAuthorizationToken
}
