// Component states
import states from './block-builder-panel.state.js'

// Components
import MdiDelete from 'vue-material-design-icons/Delete.vue'
import MdiClose from 'vue-material-design-icons/Close.vue'

// Services
import { globalstates, closeBlockBuilderPanel, shiftShortcuts } from '@/services/states/states.service'

// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'

// Libraries
import {
  get as _get
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Block-builder-panel'

// Vue@data
const data = function () {
  return {
    states,
    shortcuts,
    blockBuilderPanelState: _get(globalstates, 'leftPanel.blockBuilderPanel', {}),
    form: {
      name: ''
    },
    rules: {
      name: [
        {
          required: true,
          message: this.$t('t_builder-error-name_'),
          trigger: 'blur'
        }
      ]
    }
  }
}
// Vue@data

// Vue@subComponents
const components = {
  MdiDelete,
  MdiClose
}
// Vue@subComponents

// Methods
const methods = {

  shiftShortcuts: shiftShortcuts,

  // Func@closePanel
  /**
   * closePanel
   * Set panel inactive and clean content
   */
  closePanel () {
    closeBlockBuilderPanel()
  },
  // Func@closePanel

  // Func@save
  /**
   * Save new block
   * @param  {String} method (put or patch)
   */
  async save () {
    if (!this.hasJwtToken) {
      console.warn('No jwt Token: Save request is disabled')
      return
    }

    try {
      this.states.loading = true
      this.api
        .blocks()
        .save({
          name: this.form.name,
          block: this.blockBuilderPanelState.blocks
        })
    } finally {
      this.form.name = ''
      this.$set(this.blockBuilderPanelState, 'blocks', [])
      this.states.loading = false
      this.closePanel()
    }
  },
  // Func@save

  // Func@submitForm
  /**
   * Submit the creation of blocks
   * @param  {formName} form to submit
   * @return {Object}    (content data)
   */
  submitForm (formName) {
    this.$refs[formName]
      .validate(valid => {
        if (!valid) return false
        this.save()
      })
  }
  // Func@submitForm

}

// Computed Methods
const computed = {

  // Func@hasJwtToken
  /**
   * Check if we are able to reach API
   * @return {Boolean}
   */
  hasJwtToken () {
    return Boolean(this.api.token().get())
  }
  // Func@hasJwtToken
}

// Vue component syntax
export default {
  name,
  data,
  methods,
  computed,
  components
}
