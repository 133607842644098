// Services
import { globalstates } from '@/services/states/states.service'

// Library
import {
  get as _get
} from 'lodash-es'

export default {
  active: 'settings',
  activeSections: [],
  sections: [
    // TODO: to remove after feature (#640) validation
    // {
    //   id: 'template',
    //   title: 't_default-template_',
    //   component: 'default-template-modifier'
    // },
    {
      id: 'settings',
      title: 't_default-settings_',
      component: 'settings-general-modifier'
    },
    {
      id: 'opengraph',
      title: 't_opengraph-modifier_',
      component: 'opengraph-modifier'
    },
    {
      id: 'shortcuts',
      title: 't_shortcuts-modifier_',
      component: 'shortcuts-modifier'
    }
  ],
  leftPanel: _get(globalstates, 'leftPanel', {})
}
