export default {
  imagesOnError: {},
  forceOverflow: false,
  contentIsHover: {
    value: false,
    position: null
  },
  drag: {
    active: false,
    isSource: false
  },
  config: {
    orientation: 'vertical',
    onDrag: false,
    dropPlaceholderOptions: {
      className: 'drop-preview',
      animationDuration: '150',
      showOnTop: true
    }
  }
}
