/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Component-loader'

// Vue@properties
const props = {
  conf: Object,
  path: String,
  mjml: Object,
  show: Boolean
}
// Vue@properties

// Computed Methods
const computed = {
  // Func@loadedComponent
  /**
   * Import component on demand
   * @return {Vue} (Vue component)
   */
  loadedComponent () {
    const path = `components/standalone/${this.path}`
    return () => import('@/' + path + '.vue')
  }
  // Func@loadedComponent
}

export default {
  name,
  props,
  computed
}
