export default {
  loading: false,
  selected: {
    id: null,
    label: null
  },
  save: {
    active: false,
    confirm: false,
    overwrite: false,
    value: null
  },
  templateForm: {
    name: ''
  },
  templates: [
    {
      id: 1,
      label: 'First template'
    },
    {
      id: 2,
      label: 'Second template'
    },
    {
      id: 3,
      label: 'Third template'
    },
    {
      id: 4,
      label: 'Fourth template'
    },
    {
      id: 5,
      label: 'Fifth template'
    },
    {
      id: 6,
      label: 'Sixth template'
    },
    {
      id: 7,
      label: 'Seventh template'
    },
    {
      id: 8,
      label: 'Eighth template'
    },
    {
      id: 9,
      label: 'Ninth template'
    },
    {
      id: 10,
      label: 'Tenth template'
    }
  ],
  visibleDeleteDialog: false
}
